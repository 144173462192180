export enum HttpStatus {
  OK = 200,
  Conflict = 409,
  Bad_Request = 404,
  Locked = 423,
}

export enum HeaderOption {
  LOGOUT = 'Logout',
  MY_PROFILE = 'My Profile',
}

export enum SidebarMenu {
  DASHBOARD = 'Dashboard',
  PRODUCT_MANAGEMENT = 'Product Management',
  USER_MANAGEMENT = 'User Management',
  PACKAGE_MANAGEMENT = 'Package Management',
  WORK_QUEUE = 'Work Queue',
  CUSTOM_WORK_QUEUE = 'Custom Work Queue',
  BLOG = 'Blog Management',
  ROLES_AND_PERMISSIONS = 'Roles and Permissions'
}

export enum TrendColor {
  RED = 'red',
  GREEN = 'green',
}

export enum TrendArrow {
  UP = 'up',
  Down = 'down',
}

export enum IconType {
  SUCCESS = 'SuccessIcon',
  ALERT = 'DialogAlertIcon',
  DELETE = 'DialogDeleteIcon',
}

export enum ButtonType {
  CONTINUE = 'Continue',
  CLOSE = 'Close',
  DONE = 'Done',
  DELETE = 'Delete',
  CANCEL = 'Cancel',
  LEAVE = 'Leave Page',
  SAVE = 'Save Changes',
  STAY = 'Stay Here',
  APPROVE = 'Approve',
  DECLINE = 'Decline',
  PUBLISH = 'Publish',
}

export enum DialogHeading {
  CONGRATULATIONS = 'Congratulations!',
  ERROR = 'Error Occurred',
  THANKS = 'Thanks!',
  DELETE = 'Are you sure you want to delete?',
  APPROVE = 'Are you sure you want to approve?',
  DECLINE = 'Are you sure you want to decline?',
  PUBLISH = 'Are you sure you want to publish?',
}

export enum SortingOrder {
  ASC = 'asc',
  DSC = 'desc',
}

export enum EllipsisOptions {
  VIEW_PAGE = 'view_page',
  EDIT = 'edit',
  ADD_COMMENT = 'add_comment',
}

export enum layoutOptionsValue {
  'COL_6_TEXT_FIRST' = 'text-first;6',
  'COL_6_TEXT_LAST' = 'text-last;6',
  'COL_12_TEXT_LEFT' = 'text-left;12',
  'COL_12_TEXT_RIGHT' = 'text-right;12',
}

export enum gridLayoutFormat {
  'COL_6' = '6',
  'COL_12' = '12',
}
export enum statusTask {
  'UNPUBLISHED' = 'Unpublished Reason',
  'GENERAL' = 'General',
  'PUBLISHED' = 'Published',
}

export enum packageImageOptionValue {
  'STARTER' = 'https://marketplace-bucket-assets.s3.amazonaws.com/a11509starter-pkg-img.svg',
  'BASIC' = 'https://marketplace-bucket-assets.s3.amazonaws.com/323ec2basic-pkg-img.svg',
  'STANDARD' = 'https://marketplace-bucket-assets.s3.amazonaws.com/cf1d13standard-pkg-img.svg',
  'PREMIUM' = 'https://marketplace-bucket-assets.s3.amazonaws.com/d0d858premium-pkg-img.svg',
}

export enum pricingModelOptions {
  FREE_TRIAL = 'Free Trial',
  STANDARD_PRICING = 'Standard Pricing',
}

export enum ProductState {
  LIVE = 'Live',
  DRAFT = 'Draft',
  UNDER_DEVELOPMENT = 'Under Development',
}

export enum DashboardTabName {
  Business_KPIs_Dashboard = 0,
  Subscribed_Products_Features_Dashboard = 1,
  Subscriber_Usage_Dashboard = 2,
}

export enum PaymentMethodAction {
  APPROVE = 'Approve',
  DECLINE = 'Decline',
  PROOF_OF_PAYMENT = 'Proof of Payment',
}

export enum PaymentMethodStatus {
  APPROVED = 'Approved',
  DECLINED = 'Declined',
  PENDING = 'Pending',
}

export enum PaidState {
  PAID = 'paid',
  FREE = 'free',
}

export enum SortingColumn {
  COMPANY_NAME = 'company_name',
  PRODUCT_NAME = 'product_name',
  TRANSACTION_PERFORMED = 'transaction_performed',
  ALLOWED_TRANSACTIONS = 'allowed_transactions',
  TOTAL_SUB_USERS = 'total_sub_users',
  COUNTRY = 'country',
  PAYMENT_STATE = 'payment_state',
  ONBOARDING_DATE = 'onboarding_date',
  SUBSCRIPTION_PERIOD = 'subscription_period',
}

export enum Status {
  ACTIVE = 'Active',
  IN_ACTIVE = 'In-Active',
}

export enum WorkQueueQueryTypes {
  BANK_TRANSFER_REQUEST = 'Bank transfer',
  PAYMENT_PROOF_STATUS_UPDATE = 'Proof of payment',
}

export enum BlogCommentType {
  PUBLIC = 'Public',
  PRIVATE = 'Private',
}

export enum Validation {
  REQUIRED = 'required',
  EMAIL = 'email',
  WHITE_SPACE = 'whiteSpace',
  ONLY_ALPHABETS = 'onlyAlphabets',
  PHNONE_NUMBER = 'phoneNumber',
  LIMIT_LENGTH = 'limitLength',
  CROSS_VALIDATE = 'crossValidate',
  ONLY_NUMBERS = 'onlyNumbers'
}

export enum PaymentMethodType {
  CARD = 0,
  BANK_TRANSFER = 1,
}

export enum PaymentMethod {
  CARD = 'Card',
  BANK_TRANSFER = 'BankTransfer',
}

export enum PaymentFrequency {
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  HALF_YEARLY = 'Half Yearly',
  YEARLY = 'Yearly'
}