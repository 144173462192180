import Http from '@shared/helper/http-api'
import {
  useQuery,
} from 'react-query'
import { type AppError } from 'single-spa'

const BASE_URL = process.env.BASE_URL
export const GET_CUSTOM_QUEUE = 'marketplace/admin/payment/get-custom-queue'


export const useGetCustomQueue = (): {
  data: any[] | undefined
  error: AppError | unknown
  isLoading: boolean
} => {
  const { data, error, isLoading } = useQuery<any[]>(
    GET_CUSTOM_QUEUE,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(GET_CUSTOM_QUEUE)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error, isLoading }
}

