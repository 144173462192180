import Http from '@shared/helper/http-api'
import {
  type IPaymentTenantDetails,
} from '@shared/typings'
import {
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { type AppError } from 'single-spa'

const BASE_URL = process.env.BASE_URL
const base_path = "marketplace/admin/"
export const GET_PRODUCTS_LIST = base_path + 'product/get-all-products'
export const GET_REQUEST_DATA = base_path +  'payment/get-custom-request'
const ADD_PRICE = base_path + "payment/add-price"
const RESEND_DEEP_LINK = base_path + "payment/resend-deep-link"
const DELETE_REQUEST = base_path + "payment/delete-request"

export const useGetProductsList = (): {
  data: any[] | undefined
  error: AppError | unknown
  isLoading: boolean
} => {
  const { data, error, isLoading } = useQuery<any[]>(
    GET_PRODUCTS_LIST,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(GET_PRODUCTS_LIST)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error, isLoading }
}

export const useAddPricing = (): any => {
  const queryClient = useQueryClient()

  return useMutation<any, any>(
    async (body) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(ADD_PRICE, body)
    },
    {
      onSuccess () {
        void queryClient.invalidateQueries("FETCH_BLOG_COMMENTS")
      }
    }
  )
}

export const useResendDeepLink = (): any => {
  const queryClient = useQueryClient()

  return useMutation<any, any>(
    async (body) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(RESEND_DEEP_LINK, body)
    }
  )
}

export const useDeleteRequest= (): any => {
  const queryClient = useQueryClient()

  return useMutation<any, any>(
    async (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.delete<any>(`${DELETE_REQUEST}/${body.id}`, )
    }
  )
}

export const useGetRequestData = (request_id): {
  data: any | undefined
  error: AppError | unknown
  isLoading: boolean
} => {
  const { data, error, isLoading } = useQuery<any>(
    GET_REQUEST_DATA,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(GET_REQUEST_DATA + `/${request_id}`)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error, isLoading }
}

