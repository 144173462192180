import { useTheme } from '@mui/material'
import { LayoutWithSideNavComponent } from '../../../Layout/components'
import {} from './CustomWorkQueueStyle'
import {
} from '@modules/WorkQueue/components'
import { CustomWorkQueuePageHeaderComponent, CustomWorkQueueTableComponent } from '@modules/CustomWorkQueue/components'

export const CustomWorkQueuePage = () => {
  const theme = useTheme()

  return (
    <>
      <CustomWorkQueuePageHeaderComponent />
      <CustomWorkQueueTableComponent />
    </>
  )
}
