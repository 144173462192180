import { type FC } from 'react'
import { useTheme } from '@mui/material'
import { DuoTab, Grid } from '@ntpkunity/controls'
import { ContentWrap } from './DashboardTabsStyle'
import { TotalSubscribersComponent } from '../TotalSubscribers/TotalSubscribersComponent'
import { SubscriberRetentionComponent } from '../SubscriberRetention/SubscriberRetentionComponent'
import { ChurnedSubscribersComponent } from '../ChurnedSubscribers/ChurnedSubscribersComponent'
import { TopSubscribedProductsComponent } from '../TopSubscribedProducts/TopSubscribedProductsComponent'
import { SubscriberCACComponent } from '../SubscriberCAC/SubscriberCACComponent'
import { RecurringRevenueAnalysisComponent } from '../RecurringRevenueAnalysis/RecurringRevenueAnalysisComponent'
import { TopProductsByRevenueComponent } from '../TopProductsByRevenue/TopProductsByRevenueComponent'
import { SubscriberUsageTableComponent } from '../SubscriberUsageTable/SubscriberUsageTableComponent'
import { LeadingIndicatorComponent } from '../LeadingIndicators/LeadingIndicatorComponent'
import { RevenueSplitComponent } from '../RevenueSplitBySubscription/RevenueSplitComponent'
import { type DashboardTabName } from '@shared/constants'
import { BillingDetailsTableComponent } from '../BillingDetailsTable/BillingDetailsTableComponent'

interface DashboardTabsComponentProps {
  startDate: Date
  endDate: Date
  activeTab: number
  setActiveTab: React.Dispatch<React.SetStateAction<DashboardTabName>>
}

export const DashboardTabsComponent: FC<DashboardTabsComponentProps> = ({
  startDate,
  endDate,
  activeTab,
  setActiveTab
}) => {
  const theme = useTheme()
  return (
    <DuoTab
      theme={theme}
      varient="underline"
      items={[
        {
          content: (
            <>
              <ContentWrap theme={theme} className="content-wrap">
                <Grid theme={theme} container item spacing={3}>
                  <Grid theme={theme} item xs={12} md={8}>
                    <Grid theme={theme} container item spacing={3}>
                      <Grid theme={theme} item xs={6}>
                        <TotalSubscribersComponent
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </Grid>
                      <Grid theme={theme} item xs={6}>
                        <SubscriberRetentionComponent
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </Grid>
                      <Grid theme={theme} item xs={6}>
                        <ChurnedSubscribersComponent />
                      </Grid>
                      <Grid theme={theme} item xs={6}>
                        <SubscriberCACComponent />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid theme={theme} item xs={12} md={4}>
                    <TopSubscribedProductsComponent
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </Grid>
                  <Grid theme={theme} item xs={12} md={8}>
                    <RecurringRevenueAnalysisComponent />
                  </Grid>
                  <Grid theme={theme} item xs={12} md={4}>
                    <TopProductsByRevenueComponent
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </Grid>
                  <Grid theme={theme} item xs={12} md={8}>
                    <RevenueSplitComponent
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </Grid>
                  <Grid theme={theme} item xs={12} md={4}>
                    <LeadingIndicatorComponent />
                  </Grid>
                </Grid>
              </ContentWrap>
            </>
          ),
          title: 'Business KPIs Dashboard'
        },
        {
          content: <BillingDetailsTableComponent />,
          title: 'Billing Dashboard'
        },
        {
          content: <SubscriberUsageTableComponent />,
          title: 'Subscriber Usage Dashboard'
        }
      ]}
      defaultTabIndex={0}
      value={activeTab}
      onChange={(_, value: number) => { setActiveTab(value) }}
    />
  )
}

DashboardTabsComponent.propTypes = {}

DashboardTabsComponent.defaultProps = {}
